import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion'
import { NgClass, NgStyle } from '@angular/common'
import { ChangeDetectionStrategy, Component, Input, input, numberAttribute } from '@angular/core'
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner'

@Component({
  selector: 'sb-loader',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgClass, NgStyle, MatProgressSpinnerModule],
  template: `
    <div class="wrapper" [ngClass]="{ overlay: overlay, absolute: absolute, inline: inline }" [ngStyle]="customStyle">
      <mat-spinner [strokeWidth]="stroke()" [diameter]="diameter()" />
    </div>
  `,
  styles: [
    `
      .wrapper {
        display: flex;
        align-items: center;
        width: 100%;
        justify-content: center;
        min-height: auto;
      }

      .overlay {
        background: rgba(255, 255, 255, 0.7);
        position: fixed;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        z-index: 10000;
      }

      .absolute {
        position: absolute;
      }
    `,
  ],
})
export class LoaderComponent {
  @Input() customStyle: Record<string, string> = {}
  diameter = input(50, { transform: numberAttribute })
  @Input() maxWidth = '120px'
  stroke = input(4, { transform: numberAttribute })
  @Input() translationKey: string
  private _absolute = false

  @Input()
  get absolute() {
    return this._absolute
  }

  set absolute(value: BooleanInput) {
    this._absolute = coerceBooleanProperty(value)
  }

  private _inline = false

  @Input()
  get inline() {
    return this._inline
  }

  set inline(value: BooleanInput) {
    this._inline = coerceBooleanProperty(value)
  }

  private _overlay = false

  @Input()
  get overlay() {
    return this._overlay
  }

  set overlay(value: BooleanInput) {
    this._overlay = coerceBooleanProperty(value)
  }
}
