import logger from '~core/utils/logger'

export const copyToClipboard = async (text: string) => {
  try {
    return navigator.clipboard.writeText(text)
  } catch (err) {
    logger.error('Failed to copy: ', err)
  }
}

export const clearAndUpper = (text: string): string => text.replace(/-/, '').toUpperCase()

export const toCamelCase = (text: string): string => text.replace(/-\w/g, clearAndUpper)

export const toPascalCase = (text: string): string => text.replace(/(^\w|-\w)/g, clearAndUpper)

export const capitalize = (text: string): string => text.charAt(0).toUpperCase() + text.slice(1).toLowerCase()

export const upperFirst = (text: string): string => text.charAt(0).toUpperCase() + text.slice(1)
